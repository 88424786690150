<template>
  <div>
    <div class="app-content content">
      <div class="content-overlay" />
      <div class="header-navbar-shadow" />
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">
                <h2 class="content-header-title float-start mb-0">
                  {{ convo.id ? 'Edit' : 'Add' }} Promotion Call{{ convo.id ? `: ${convo.title}` : '' }}
                </h2>
              </div>
            </div>
          </div>
          <div
            class="
              content-header-right
              text-md-end
              col-md-3 col-12
              d-md-block d-none
            "
          >
            <div class="mb-1 breadcrumb-right">
              <a
                class="btn btn-icon btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Back to Senior call list"
                @click="$router.push({name: 'admin.calls.promotion.index'})"
              ><i data-feather="chevron-left" /></a>
              <a

                class="btn btn-icon btn-flat-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Senior call settings"
              ><i data-feather="settings" /></a>
            </div>
          </div>
        </div>
        <div class="content-body content-body--senior-call">
          <form id="form">
            <div class="row">
              <div
                v-if="convo.id || !convoId"
                class="col-12"
              >
                <BasicInformation
                  :type="'promotion'"
                  @saveAnswer="saveAnswer"
                />

                <Dates
                  :type="'promotion'"
                  @saveAnswer="saveAnswer"
                />

                <AccessUsers
                  @saveAnswer="saveAnswer"
                />

                <!-- <AddFile
                  :id="6"
                  :title="'Expression of Interest Form'"
                  :type="1"
                  :files="convo.interest_files"
                  :description="convo.description_interest_form"
                  @saveAnswer="saveAnswer"
                />

                <OtherTexts
                  :referees="false"
                  @saveAnswer="saveAnswer"
                /> -->

                <AddFile
                  :id="7"
                  :title="'Support documentation'"
                  :type="3"
                  :files="convo.support_files"
                  @saveAnswer="saveAnswer"
                />

                <ProtectionContract
                  @saveAnswer="saveAnswer"
                />
              </div>
            </div>
          </form>
          <div
            class="
              page-actions
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div>
              <div v-if="convoId">
                <button
                  id="type-success"
                  :class="{'d-none': loading}"
                  class="btn btn-info me-21"
                  @click="save"
                >
                  <i data-feather="command" /> Update
                </button>
                <button
                  :class="{'d-none': !loading}"
                  class="btn btn-outline-info"
                  type="button"
                  disabled=""
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span class="ms-25 align-middle">Updating...</span>
                </button>
              </div>
              <div v-else>
                <button
                  v-if="!loading"
                  id="type-success"
                  class="btn btn-success"
                  @click="save"
                >
                  <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
                </button>
                <button
                  v-else
                  class="btn btn-outline-success"
                  type="button"
                  disabled=""
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span class="ms-25 align-middle">Saving...</span>
                </button>
              </div>
            </div>
            <!-- <div>
              <div class="btn-group dropup">
                <button
                  id="dropdownMenuButton5"
                  class="btn btn-warning dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  View preview
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton5"
                >
                  <a
                    class="dropdown-item"
                  >View applicants</a>
                  <a
                    class="dropdown-item"
                  >View hosts</a>
                  <a
                    class="dropdown-item"
                  >View referees</a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Form from 'vform'
import Vue from 'vue'
import AddFile from '../senior-call/components/AddFile.vue'
import BasicInformation from '../senior-call/components/BasicInformation.vue'
import Dates from '../senior-call/components/Dates.vue'
import AccessUsers from '../senior-call/components/AccessUsers.vue'
import ProtectionContract from './components/ProtectionContract.vue'

export default {
  components: {
    AddFile,
    BasicInformation,
    Dates,
    ProtectionContract,
    AccessUsers,
  },
  data() {
    return {
      convoId: this.$route.params.id,
      sections: {},
      loading: false,
      files: [],
      form: new Form({ data: {} }),
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      sended: 'convo/sended',
    }),
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      if (this.convoId) {
        await this.$store.dispatch('convo/fetchSpecific', this.convoId)
      } else {
        await this.$store.dispatch('convo/removeConvo')
      }

      await this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
      await this.$store.dispatch('users/fetchModerators')
      await this.$store.dispatch('access/fetchAll', 3)
      feather.replace({
        width: 14,
        height: 14,
      })
    },
    async save() {
      this.loading = true

      try {
        this.form.data.convo_type_id = 3
        if (this.convo.id) {
          await this.$store.dispatch('convo/update', { convoId: this.convoId, form: this.form })
          if (this.sended === true) {
            Vue.swal('Promotion call updated successfully!', '', 'success')
          }
        } else {
          await this.$store.dispatch('convo/create', { form: this.form })
          if (this.convo.id) {
            Vue.swal('Promotion call created successfully!', '', 'success').then(() => {
              this.$router.push({
                name: 'admin.calls.promotion.index',
              })
            })
          } else {
            // Vue.swal
          }
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    saveAnswer(data) {
      this.form.data[data.id] = data.data
    },
  },
}
</script>
